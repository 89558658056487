import {
    SHOW_ADVISORY,
    ADVISORY_LOADING,
    ADVISORY_VISIBLE,
    ADVISORY_STATUS_CREATE,
    ADVISORY_STATUS_ACTIONS,
    ADD_ADVISORY,
    LIST_ADVISER,
    DELETE_ADVISORY,
    GET_ADVISORY,
    UPDATE_ADVISORY,
    ACTIVE_ADVISORY,
    RESPONSE_ADVISORY,
    LIST_ADVISORIES,
    SHOW_ADVISORY_REPORT,
    LIST_PROGRAMINGS

} from "../ActionTypes";

const INIT_STATE = {
    advisoryList: [],
    programingList: [],

    advisoryReportList: [],
    responseAdvisory:'',
    advisertListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    expertObject: [],
    expertStatusCreate: 0,
    advisoryStatusActions: 0,
    loading:false,
    visible:false,
    advisoriesList: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_ADVISORY: {
            return {
                ...state,
                advisoryList: action.payload,
            }
        }

        case LIST_PROGRAMINGS: {
            return {
                ...state,
                programingList: action.payload,
            }
        }

        case SHOW_ADVISORY_REPORT: {
            return {
                ...state,
                advisoryReportList: action.payload,
            }
        }

        
        case ACTIVE_ADVISORY: {
            return {
                ...state
            }
        }


        case ADVISORY_STATUS_ACTIONS: {
            return {
                ...state,
                advisoryStatusActions:action.payload
            }
        }
        
        case ADVISORY_STATUS_CREATE: {
            return {
                ...state,
                expertStatusCreate:action.payload
            }
        }

        case ADVISORY_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case  ADVISORY_VISIBLE : {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_ADVISORY: {
            return {
                ...state
            }
        }
      
        case GET_ADVISORY: {
            return {
                ...state,
                expertObject : action.payload
            }
        }

        case LIST_ADVISER: {
            return {
                ...state,
                advisertListSelect: action.payload,
            }
        }   

        case LIST_ADVISORIES: {
            return {
                ...state,
                advisoriesList: action.payload,
            }
        }   
        
        case RESPONSE_ADVISORY: {
            return {
                ...state,
                responseAdvisory: action.payload,
            }
        } 


        case DELETE_ADVISORY: {
            return {
                ...state
            }
        }


        case UPDATE_ADVISORY: {
            return {
                ...state
            }
        }

      default:
        return state;
    }
  }
  