import React from "react";
import { Col, Layout, Menu, Row } from "antd";
import { useRouteMatch } from "react-router-dom";
import NavBar from "../../components/NavBar";
import "../../styles/kernel.scss";
import "../../styles/home.scss";
import "../../styles/register.scss";
import { useDispatch, useSelector } from "react-redux";

import App from "../../routes/index";

const { Header, Content, Footer } = Layout;

const MainApp = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const authUser = useSelector(({ auth }) => auth.authUser);

    const fechaActual = new Date();
    const yearNow = fechaActual.getFullYear();
    let menuList = [];
    if (authUser) {
        switch (authUser.idGroup) {
            case 0:
                menuList = [
                    { /*menu: "Gestión de Clientes", link: "/base/client/list"*/ },
                ];
                break;
             
        }
    }

    return (
        <Layout>
            <NavBar items={menuList} />
            <Content className="site-layout">
                <App match={match} />
            </Content>
            <Footer className="footer">
                <Row style={{width: '100%'}}>
                    <Col xs={{ span:24}} sm={{ span:12}} md={{ span:12}} lg={{ span:12}}>
                    <p className="user">{`${JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).group : ''} / ${JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).fullName : ''}`}</p>
                    </Col>
                    <Col xs={{ span:24}} sm={{ span:12}} md={{ span:12}} lg={{ span:12}}>
                    <p className="create">{yearNow} <a style={{}}  target="_blank" href="https://iteprevengo.com/">ITEPREVENGO</a></p>
                    </Col>
                </Row>
            </Footer>
        </Layout>
    );
};
export default MainApp;
