import {
  REGISTER_LOADING,
  REGISTER_VISIBLE,
  REGISTER_STATUS_CREATE,
  REGISTER_STATUS_ACTIONS,
  ADD_REGISTER,
  REGISTER_USER_NAME,
  REGISTER_MESSAGE

} from "../ActionTypes";

const INIT_STATE = {
  registerList: [],
  registerListSelect:[],
  meta:{
      totalData:0,
      pageSize:0,
      current:0,
      totalPages:0
  },
  registerObject: [],
  registerStatusCreate: 0,
  registerStatusActions: 0,
  userName:'',
  register_message:'',
  loading:false,
  visible:false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

       
      case REGISTER_STATUS_ACTIONS: {
          return {
              ...state,
              registerStatusActions:action.payload
          }
      }
      
      case REGISTER_STATUS_CREATE: {
          return {
              ...state,
              registerStatusCreate:action.payload
          }
      }

      case REGISTER_LOADING: {
          return {
          ...state,
          loading: action.payload,
          }
      }
      case REGISTER_MESSAGE: {
        return {
        ...state,
        register_message: action.payload,
        }
    }
      case REGISTER_USER_NAME: {
        return {
        ...state,
        userName: action.payload,
        }
    }
      case REGISTER_VISIBLE: {
          return {
          ...state,
          visible: action.payload,
          }
      }

      case ADD_REGISTER: {
          return {
              ...state
          }
      }
  

      

    default:
      return state;
  }
}
