import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {hideMessage} from "../../appRedux/actions/Common";
import {message} from "antd";

const InfoView = () => {

  const dispatch = useDispatch();

  const error = useSelector(({commonData}) => commonData.error);

  const loading = useSelector(({commonData}) => commonData.loading);

  const displayMessage = useSelector(({commonData}) => commonData.message);

  
  useEffect(() => {
    if (error || message) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
    }
  }, [error, message, dispatch]);


  return (
    <>
      {error && message.error? console.log("render"):''}
      {error && message.error(<span id="message-id">{error}</span>)}
      {displayMessage && message.info(<span id="message-id">{displayMessage}</span>)}
    </>
  );
};

export default InfoView;
