import {
    SHOW_AVAILABILITY,
    AVAILABILITY_LOADING,
    AVAILABILITY_VISIBLE,
    AVAILABILITY_STATUS_CREATE,
    AVAILABILITY_STATUS_ACTIONS,
    ADD_AVAILABILITY,
    LIST_ADVISER,
    DELETE_AVAILABILITY,
    GET_AVAILABILITY,
    UPDATE_AVAILABILITY,
    // ACTIVE_AVAILABILITY,
    RESPONSE_AVAILABILITY,
    HOURS_AVAILABILITY

} from "../ActionTypes";

const INIT_STATE = {
   availabilityList: [],
    responseAdvisory:'',
    availabilityListSelect:[],
    hoursAvailability:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
   availabilityObject: [],
   availabilityStatusCreate: 0,
   availabilityStatusActions: 0,
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_AVAILABILITY: {
            return {
                ...state,
               availabilityList: action.payload,
            }
        }
        // case ACTIVE_AVAILABILITY: {
        //     return {
        //         ...state
        //     }
        // }


        case AVAILABILITY_STATUS_ACTIONS: {
            return {
                ...state,
               availabilityStatusActions:action.payload
            }
        }
        
        case AVAILABILITY_STATUS_CREATE: {
            return {
                ...state,
               availabilityStatusCreate:action.payload
            }
        }

        case AVAILABILITY_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case  AVAILABILITY_VISIBLE : {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_AVAILABILITY: {
            return {
                ...state
            }
        }
      
        case GET_AVAILABILITY: {
            return {
                ...state,
               availabilityObject : action.payload
            }
        }

        case HOURS_AVAILABILITY: {
            return {
                ...state,
                hoursAvailability : action.payload
            }
        }

        case LIST_ADVISER: {
            return {
                ...state,
                availabilityListSelect: action.payload,
            }
        }   
        
        case RESPONSE_AVAILABILITY: {
            return {
                ...state,
                responseAdvisory: action.payload,
            }
        } 

        case DELETE_AVAILABILITY: {
            return {
                ...state
            }
        }


        case UPDATE_AVAILABILITY: {
            return {
                ...state
            }
        }

      default:
        return state;
    }
  }
  