import React, { useState, useEffect } from "react"
import {
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Tag,
  message,
  Checkbox,
  Calendar,
} from "antd"
import { useSelector, useDispatch } from "react-redux"
import {
  LeftOutlined,
  CheckOutlined,
  MailOutlined,
  CheckCircleOutlined,
  PlusOutlined,
  DeleteOutlined
} from "@ant-design/icons"
import { Layout, Radio } from "antd"
import { Divider } from "antd"

import { useLocation, Link } from "react-router-dom"

import axios from "../../util/Api"

import "../../styles/advisory.scss"
import { setResponsive } from "../../appRedux/actions/Responsive"
import { getUser } from "../../appRedux/actions/Auth"
import NavBar from "../../components/NavBarPublic"

import locale from "antd/es/date-picker/locale/es_ES"
import "moment/locale/es-mx"
import moment from "moment"

const { Content, Footer } = Layout

const Inscription = () => {
  const query = new URLSearchParams(useLocation().search)

  const RegexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})+$/
  const id = query.get("id")
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const responsive = useSelector((store) => store.responsive.value)

  const [tabActive, setTabActive] = useState(0)
  const [tabActiveMobile, setTabActiveMobile] = useState(0)

  const [loading, setLoading] = useState(false)

  const [employee, setEmployee] = useState({})
  const [availability, setAvailability] = useState([moment(), moment()])
  const [selectedValue, setSelectedValue] = useState(moment())
  const [selectedHours, setSelectedHours] = useState("")
  const [hoursAvailability, setHoursAvailability] = useState([])
  const [fName, setFName] = useState("")
  const [lName, setLName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [company, setCompany] = useState("")
  const [numberEmployee, setNumberEmployee] = useState(0)
  const [emailcc, setEmailcc] = useState([])


  const [response, setResponse] = useState([])

  const fechaActual = new Date();
  const yearNow = fechaActual.getFullYear();

  const CheckboxGroup = Checkbox.Group;
  const [plainOptions, setPlainOptions] = useState([]);
  const defaultCheckedList = [];

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const checkAll = plainOptions.length === checkedList.length;

  const onChange = (list) => {
    setCheckedList(list);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };

  useEffect(() => {
    dispatch(setResponsive())
    getEmployee()
  }, [])

  const getEmployee = () => {
    axios
      .get("/external/employees/" + id, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(({ data }) => {
        setEmployee(data.data)

        if (data.data.document === '42510771') {
          setPlainOptions(['Itpsicosocial (evaluación de riesgo psicosial)', 'Mype Segura (implementación SST para mypes)', 'Itprevengo Software (software sst)'])

        } else {
          setPlainOptions(['Itprevengo Software (software sst)', 'Mype Segura (implementación SST para mypes)', 'Itpsicosocial (evaluación de riesgo psicosial)', 'CEPAR (entrenamiento de alto riesgo)'])

        }

        onSelect(moment(new Date(), "YYYY-MM-DD"))
        // setAvailability([
        //     moment(data.data.availability.today, "YYYY-MM-DD"),
        //     moment(data.data.availability.endDate != null ? data.data.availability.endDate : data.data.availability.finish, "YYYY-MM-DD"),
        // ])

        setAvailability(data.data.availability)
      })
      .catch(function (error) {
        console.log("Error****:", error)
      })
  }

  const addDetail = (object) => {
    axios
      .post("/external/availability/addDetail", object, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(({ data }) => {
        setHoursAvailability(data.hours)
      })
      .catch(function (error) {
        console.log("Error****:", error)
        setHoursAvailability([])
      })
  }


  const addAdvisory = (object) => {
    axios
      .post("/external/advisory", object, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(({ data }) => {
        setResponse(data)
        setTabActive(2)
        setTabActiveMobile(3)
        setLoading(false)
      })
      .catch(function (error) {
        console.log("Error****:", error)
        if (error.response) {
          message.error(error.response.data.message)
        } else {
          message.error("Error de red")
        }
        setLoading(false)
      })
  }

  const onSelect = (value) => {
    setSelectedValue(value)
    addDetail({ date: value.format().substr(0, 10), idAdviser: id })
  }

  const handleSelectedHour = (hour, occupied) => {
    // let hours = selectedHours.slice();
    // hours = hours.includes(hour) ? hours.filter(h => h != hour) : hours.length==0? hours.concat(hour):hours.concat('||'+hour);
    if (occupied == "OCUPADO") {
      message.warning("No disponible")
      return false
    } else {
      setSelectedHours(hour)
    }
  }

  const next = () => {
    if (selectedHours === "") {
      message.warning("Seleccione una hora")
      return false
    } else {
      setTabActive(1)
    }
  }

  const nextMobile = () => {
    if (selectedHours === "") {
      message.warning("Seleccione una hora")
      return false
    } else {
      setTabActiveMobile(2)
    }
  }

  const validateEmail = (value) => {
    return RegexEmail.test(value.trim());
  }


  const validate = async () => {


    let $msg = ""
    if (email == "") {
      $msg = "Ingrese un correo"
    }
    if (!validateEmail(email)) {
      $msg = "La dirección de email es incorrecta."
    }

    // if (lName == "") {
    //   $msg = "Ingrese sus apellidos"
    // }

    if (checkedList.length === 0) {
      $msg = "Seleccione al menos un servicio"
    }

    console.log(checkedList.join(', '))

    if (fName == "") {
      $msg = "Ingrese su nombre"
    }

    if (company == "") {
      $msg = "Ingrese el nombre de su empresa"
    }

    if (emailcc.length > 0) {
      for (const email of emailcc) {
        if (!validateEmail(email)) {
          $msg = "Correo de invitados presentan formato incorrecto";
          break;
        }
      }
    }

    if ($msg != "") {
      message.warning($msg)
    }
    return $msg
  }

  const register = async () => {
    if (await validate() == "") {
      setLoading(true)

      addAdvisory({
        service: checkedList.join(', '),
        modality: "Virtual",
        idAdviser: id,
        date: selectedValue.format().substr(0, 10),
        hours: selectedHours,
        fName: fName,
        lName: '',
        email: email.trim(),
        phone: phone,
        company: company,
        ccEmail: emailcc.map(v => v.trim()).join(','),
        numberEmployee: numberEmployee

      })
    }
  }

  const registerMobile = async () => {
    if (await validate() == "") {
      addAdvisory({
        service: checkedList.join(', '),
        modality: "Virtual",
        idAdviser: id,
        date: selectedValue.format().substr(0, 10),
        hours: selectedHours,
        fName: fName,
        lName: '',
        email: email.trim(),
        phone: phone,
        company: company,
        ccEmail: emailcc.map(v => v.trim()).join(','),
        numberEmployee: numberEmployee,
      })
    }
  }

  return (
    <Layout>
      <NavBar />
      {!responsive ? (
        <Content className="site-layout" style={{ marginTop: "1rem" }}>
          <Row>
            <Col xs={20} lg={8} style={{ textAlign: "center" }}>
              <Row style={{ justifyContent: "space-around" }}>
                <Col xs={20} lg={8} style={{ textAlign: "center" }}>
                  <img
                    src={
                      employee.photo
                        ? process.env.REACT_APP_BUCKET + employee.photo
                        : "/user.png"
                    }
                    height="150px"
                    width="150px"
                    style={{ borderRadius: "100px" }}
                  />
                  <br></br>
                  <b style={{ fontSize: "16px" }} className="primary">{employee.fullName}</b>
                </Col>
                {
                  employee.document == '72869516' ?
                    <Col xs={20} lg={8} style={{ textAlign: "center" }}>
                      <img
                        src='https://landing-comitesst.iteprevengo.com/assets/images/citas/Herbert.png'
                        height="150px"
                        width="150px"
                        style={{ borderRadius: "100px" }}
                      />
                      <br></br>

                      <b style={{ fontSize: "16px" }} className="primary">Herbert Parimango</b>
                    </Col>
                    : <></>
                }

              </Row>
              <h1>Agenda una reunión</h1>

              {tabActive == 0 ? (
                <p>

                </p>
              ) : (
                ""
              )}
              {tabActive == 1 ? (
                <p>Complete el formulario para agendar su cita</p>
              ) : (
                ""
              )}
              {tabActive == 2 ? (
                <p>Se ha generado su cita correctamente</p>
              ) : (
                ""
              )}
            </Col>
            <Col xs={4} lg={1}>
              <Divider
                type="vertical"
                style={{ backgroundColor: "#f29314", height: "100%" }}
              />
            </Col>
            <Col xs={24} lg={15}>
              <Row style={tabActive == 0 ? {} : { display: "none" }}>
                <Col xs={24} lg={12} className="advisorCard">
                  <p className="primary">Seleccione fecha</p>

                  <Calendar
                    // validRange={availability}
                    showTime
                    locale={locale}
                    onSelect={onSelect}
                    fullscreen={false}
                    style={{ displey: "block" }}
                    disabledDate={(current) => {
                      if (
                        !availability.includes(current.format().substr(0, 10))
                      ) {
                        return current
                      }
                    }}
                  />
                </Col>
                <Col xs={4} lg={1}></Col>
                <Col xs={24} lg={10} className="advisorCard">
                  <p
                    style={{
                      textAlign: "center",
                      color: "#072d70",
                    }}
                  >
                    Seleccione hora (Lima/Bogotá)
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      color: "#072d70",
                    }}
                  >
                    {selectedValue && selectedValue.format("dddd D [de] MMMM")}
                  </p>

                  <div style={{ textAlign: "center" }}>
                    {hoursAvailability.length > 0 ? (
                      hoursAvailability.map((hour, i) => (
                        <span
                          onClick={() => {
                            handleSelectedHour(hour.hour, hour.occupied)
                          }}
                          className={`hourTag ${hour.occupied == "OCUPADO"
                            ? "hourOccupied"
                            : selectedHours.includes(hour.hour)
                              ? "hourSelected"
                              : "hourNotSelected"
                            }`}
                        >
                          {hour.hour.substring(0, 5)}
                        </span>
                      ))
                    ) : (
                      <p>No disponible</p>
                    )}
                  </div>
                  {hoursAvailability.length > 0 ? (
                    <div style={{ textAlign: "center" }}>
                      <Button className="btn-orange" onClick={next}>
                        Siguiente
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row style={tabActive == 1 ? {} : { display: "none" }}>
                <Col lg={24} xs={24} style={{ width: "100%" }}>
                  <Card
                    className="advisorCard"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Row>
                      <Col
                        xs={24}
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        {" "}
                        <label className="primary">
                          <b>Introduzca sus datos</b>
                        </label>
                      </Col>

                      <Col xs={6}>
                        {" "}
                        <span className="primary">
                          <b>Nombres y Apellidos <span style={{ color: "red" }}>*</span></b>
                        </span>
                      </Col>
                      <Col xs={18}>
                        {" "}
                        <Input
                          size=""
                          onChange={(e) => setFName(e.target.value)}
                        />
                      </Col>
                      {/* <Col xs={6}>
                        {" "}
                        <span className="primary">
                          <b>Apellidos</b>
                        </span>
                      </Col>
                      <Col xs={18}>
                        {" "}
                        <Input
                          size=""
                          onChange={(e) => setLName(e.target.value)}
                        />
                      </Col> */}
                      <Col xs={6}>
                        {" "}
                        <span className="primary">
                          <b>Correo <span style={{ color: "red" }}>*</span></b>
                        </span>
                      </Col>
                      <Col xs={18}>
                        {" "}
                        <Input
                          size=""
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Col>

                      <Col xs={6}>
                        {" "}
                        <span className="primary">
                          <b>Empresa <span style={{ color: "red" }}>*</span></b>
                        </span>
                      </Col>
                      <Col xs={18}>
                        {" "}
                        <Input
                          size=""
                          onChange={(e) => setCompany(e.target.value)}
                        />
                      </Col>
                      <Col xs={6}>
                        {" "}
                        <span className="primary">
                          <b>Teléfono </b>
                        </span>
                      </Col>
                      <Col xs={18}>
                        {" "}
                        <Input
                          size=""
                          onChange={(e) => setPhone(e.target.value)}
                          maxLength={15}
                        />
                      </Col>

                      <Col xs={6}>
                        {" "}
                        <span className="primary">
                          <b>N° de trabajadores </b>
                        </span>
                      </Col>


                      <Col xs={18}>
                        {" "}
                        <Input
                          size=""
                          onChange={(e) => setNumberEmployee(e.target.value)}
                          type="number"
                        />
                      </Col>

                      <Col xs={24}>
                        <br></br>
                      </Col>

                      <Col xs={6}>
                        {" "}
                        <span className="primary">
                          <b>Servicios {'(puede elegir uno o más)'} <span style={{ color: "red" }}>*</span></b>
                        </span>
                      </Col>
                      <Col xs={18} style={{ 'textAlign': 'left' }}>
                        <CheckboxGroup value={checkedList} onChange={onChange} style={{ width: '100%' }}>
                          <Row>
                            {plainOptions ?
                              plainOptions.map((val, index) => {

                                return (<Col span={24}>
                                  <Checkbox key={index} value={val}>{val}</Checkbox>
                                </Col>)
                              }) : ''
                            }




                          </Row>
                        </CheckboxGroup>

                        <Row>
                          <Col span={24}>
                            <Checkbox onChange={onCheckAllChange} checked={checkAll} >Todos </Checkbox>
                          </Col>

                        </Row>

                      </Col>


                      <Col xs={24}>
                        <br></br>
                      </Col>
                      <Col xs={6} style={{ marginTop: '12px' }}>
                        {" "}
                        <span className="primary" >
                          <b>Añade invitados  <span style={{ color: "red" }}>*</span></b>
                        </span>
                      </Col>
                      <Col xs={12} style={{ 'textAlign': 'left' }}>
                        <Row>
                          {emailcc ?
                            emailcc.map((val, index) => {

                              return (<Col span={24} style={{ display: 'flex' }}>
                                <Input key={index} placeholder="Ingrese un correo" value={val} onChange={(e) => {
                                  let emailTmp = [...emailcc]
                                  emailTmp[index] = e.target.value
                                  setEmailcc(emailTmp)
                                }} />
                                <Button
                                  className="btn-orange-outlined"
                                  onClick={() => {

                                    let emailTmp = [...emailcc]
                                    emailTmp.splice(index, 1)
                                    setEmailcc(emailTmp)
                                  }}
                                >
                                  <DeleteOutlined />
                                </Button>
                              </Col>)
                            }) : ''
                          }




                        </Row>

                        <Col span={24}>
                          <Button
                            className="btn-orange-outlined"
                            onClick={() => { if (emailcc.length < 4) setEmailcc([...emailcc, ...['']]) }}
                          >
                            <PlusOutlined /> Añadir
                          </Button>
                        </Col>


                      </Col>
                      <Col xs={24}>
                        <Button
                          className="btn-orange-outlined"
                          onClick={() => setTabActive(0)}
                        >
                          <LeftOutlined /> Anterior
                        </Button>
                        <Button
                          loading={loading}
                          className="btn-orange"
                          onClick={register}
                        >
                          Confirmar Cita <CheckOutlined />
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row style={tabActive == 2 ? {} : { display: "none" }}>
                <Col lg={24} xs={24} style={{ width: "100%" }}>
                  <Card
                    className="advisorCard"
                    style={{ marginBottom: "1rem" }}
                  >
                    {response != [] ? (
                      <div>
                        <div>
                          <div style={{
                            fontSize: "1.4rem",
                            color: "#f29314",
                            marginTop: ".5rem",
                            marginBottom: ".5rem",
                          }}><CheckCircleOutlined

                            />
                            Se ha programado su asesoría correctamente
                          </div>
                        </div>
                        <p
                          style={{
                            fontSize: "1.6rem",
                            fontWeight: "bolder",
                            color: "#4a4a4a",
                            marginBottom: "1rem",
                          }}
                        >
                          DATOS DE LA ASESORÍA
                        </p>
                        <p
                          style={{
                            fontSize: "1.4rem",
                            fontWeight: "bolder",
                            color: "#f29314",
                          }}
                        >
                          Fecha
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bolder",
                            color: "#4a4a4a",
                          }}
                        >
                          {response.date}
                        </p>
                        <p
                          style={{
                            fontSize: "1.4rem",
                            fontWeight: "bolder",
                            color: "#f29314",
                          }}
                        >
                          Asesor
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bolder",
                            color: "#4a4a4a",
                          }}
                        >
                          {response.adviser}
                        </p>
                        <p
                          style={{
                            fontSize: "1.4rem",
                            fontWeight: "bolder",
                            color: "#f29314",
                          }}
                        >
                          Modalidad
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bolder",
                            color: "#4a4a4a",
                          }}
                        >
                          Virtual
                        </p>
                        <p
                          style={{
                            fontSize: "1.4rem",
                            fontWeight: "bolder",
                            color: "#f29314",
                          }}
                        >
                          Link
                        </p>
                        <a
                          href={response.meet}
                          target="_blank"
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bolder",
                            color: "#4a4a4a",
                          }}
                        >
                          {response.meet}
                        </a>
                        <p
                          style={{
                            fontSize: "1rem",
                            marginTop: "1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          <MailOutlined /> Se te ha enviado un correo con la
                          información de la asesoría a {response.email}
                        </p>
                        <Button
                          className="btn-orange"
                          size="large"
                          onClick={() => window.location.reload()}
                        >
                          Programar otra asesoría
                        </Button>
                      </div>
                    ) : (
                      <span>Cargando ...</span>
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row >
        </Content >
      ) : (
        <Content className="site-layout" style={{ marginTop: "80px" }}>
          {/* <h1 style={{ textAlign: "center" }}>Agenda una reunión</h1> */}

          <Card
            className=""
            style={
              tabActiveMobile == 0
                ? { marginBottom: "1rem", textAlign: "center", padding: ".1px !important", borderRadius: "1rem" }
                : { display: "none" }
            }
          >
            <Row style={{ justifyContent: "space-around" }}>

              <Col xs={20} lg={8} style={{ textAlign: "center", marginBottom: "8px" }}>

                <img
                  src={
                    employee.photo
                      ? process.env.REACT_APP_BUCKET + employee.photo
                      : "/user.png"
                  }
                  height="150px"
                  width="150px"
                  style={{ borderRadius: "100px" }}
                />
                <br></br>
                <b style={{ fontSize: "16px" }} className="primary">{employee.fullName}</b>

              </Col>


              {
                employee.document == '72869516' ?
                  <Col xs={20} lg={8} style={{ textAlign: "center", marginBottom: "8px" }}>
                    <img
                      src='https://landing-comitesst.iteprevengo.com/assets/images/citas/Herbert.png'
                      height="150px"
                      width="150px"
                      style={{ borderRadius: "100px" }}
                    />
                    <br></br>

                    <b style={{ fontSize: "16px" }} className="primary">Herbert Parimango</b>
                  </Col>
                  : <></>
              }

            </Row>
            <h1>Agenda una reunión</h1>


            {tabActiveMobile == 1 ? (
              <p>Complete el formulario para agendar su cita</p>
            ) : (
              ""
            )}

            <div style={{ display: "inline-block" }}>
              <Button
                style={{ height: "min-content" }}
                className="btn-orange"
                onClick={() => setTabActiveMobile(1)}
              >
                Siguiente
              </Button>
            </div>

          </Card>
          <Card
            className="advisorCard"
            style={
              tabActiveMobile == 1
                ? { marginBottom: "1rem" }
                : { display: "none" }
            }
          >
            <Row>
              <Col xs={24}>
                <p className="primary">Seleccione fecha</p>

                <Calendar
                  // validRange={availability}
                  showTime
                  locale={locale}
                  onSelect={onSelect}
                  fullscreen={false}
                  style={{ displey: "block" }}
                  disabledDate={(current) => {
                    if (
                      !availability.includes(current.format().substr(0, 10))
                    ) {
                      return current
                    }
                  }}
                />
              </Col>
              <Col xs={1}>
                <Divider
                  type="horizontal"
                  style={{ backgroundColor: "#f29314", width: "270px" }}
                />
              </Col>
              <Col xs={24}>
                <p
                  style={{
                    textAlign: "center",
                    color: "#072d70",
                  }}
                >
                  Seleccione hora (Lima/Bogotá)
                </p>
                <p
                  style={{
                    textAlign: "center",
                    color: "#072d70",
                  }}
                >
                  {selectedValue && selectedValue.format("dddd D [de] MMMM")}
                </p>

                <div style={{ textAlign: "center" }}>
                  {hoursAvailability.length > 0 ? (
                    hoursAvailability.map((hour, i) => (
                      <span
                        onClick={() => {
                          handleSelectedHour(hour.hour, hour.occupied)
                        }}
                        className={`hourTag ${hour.occupied == "OCUPADO"
                          ? "hourOccupied"
                          : selectedHours.includes(hour.hour)
                            ? "hourSelected"
                            : "hourNotSelected"
                          }`}
                      >
                        {hour.hour.substring(0, 5)}
                      </span>
                    ))
                  ) : (
                    <p>No disponible</p>
                  )}
                </div>
                {hoursAvailability.length > 0 ? (
                  <div style={{ display: "inline-block" }}>
                    <Button
                      style={{ height: "min-content" }}

                      className="btn-orange" onClick={nextMobile}>
                      Siguiente
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Card>

          <Card
            className="advisorCard"
            style={
              tabActiveMobile == 2
                ? { marginBottom: "1rem", textAlign: "left" }
                : { display: "none" }
            }
          >
            <Row style={{ padding: '0px !important' }}>
              <Col
                xs={24}
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginBottom: "30px",
                }}
              >
                {" "}
                <label className="primary">
                  <b>Introduzca sus datos</b>
                </label>
              </Col>

              <Col xs={24}>
                {" "}
                <span className="primary">
                  <b>Nombres y Apellidos <span style={{ color: "red" }}>*</span></b>
                </span>
              </Col>
              <Col xs={24}>
                {" "}
                <Input size="" onChange={(e) => setFName(e.target.value)} />
              </Col>
              {/* <Col xs={24}>
                {" "}
                <span className="primary">
                  <b>Apellidos</b>
                </span>
              </Col>
              <Col xs={24}>
                {" "}
                <Input size="" onChange={(e) => setLName(e.target.value)} />
              </Col> */}
              <Col xs={24}>
                {" "}
                <span className="primary">
                  <b>Correo <span style={{ color: "red" }}>*</span></b>
                </span>
              </Col>
              <Col xs={24}>
                {" "}
                <Input size="" onChange={(e) => setEmail(e.target.value)} />
              </Col>
              <Col xs={24}>
                {" "}
                <span className="primary">
                  <b>Empresa <span style={{ color: "red" }}>*</span></b>
                </span>
              </Col>
              <Col xs={24}>
                {" "}
                <Input
                  size=""
                  onChange={(e) => setCompany(e.target.value)}
                />
              </Col>
              <Col xs={24}>
                {" "}
                <span className="primary">
                  <b>Teléfono</b>
                </span>
              </Col>
              <Col xs={24}>
                {" "}

                <Input size="" onChange={(e) => setPhone(e.target.value)} maxLength={15} />
              </Col>
              <Col xs={24}>
                {" "}
                <span className="primary">
                  <b>N° de trabajadores</b>
                </span>
              </Col>
              <Col xs={24}>
                {" "}
                <Input size="" onChange={(e) => setNumberEmployee(e.target.value)} type="number" />
              </Col>
              <Col xs={24}>
                {" "}
                <span className="primary">
                  <b>Servicios {'(puede elegir uno o más)'} <span style={{ color: "red" }}>*</span> </b>
                </span>
              </Col>
              <Col xs={24} style={{ 'textAlign': 'left' }}>
                <CheckboxGroup value={checkedList} onChange={onChange} style={{ width: '100%' }}>
                  <Row>

                    {plainOptions ?
                      plainOptions.map((val, index) => {

                        return (<Col span={24}>
                          <Checkbox key={index} value={val}>{val}</Checkbox>
                        </Col>)
                      }) : ''
                    }




                  </Row>
                </CheckboxGroup>

                <Row>
                  <Col span={24}>
                    <Checkbox onChange={onCheckAllChange} checked={checkAll} >Todos </Checkbox>
                  </Col>

                </Row>

              </Col>
              <Col xs={24} >
                {" "}
                <br></br>
                <span className="primary" >
                  <b>Añade invitados  <span style={{ color: "red" }}>*</span></b>
                </span>
              </Col>
              <Col xs={24} style={{ 'textAlign': 'left' }}>
                <Row>
                  {emailcc ?
                    emailcc.map((val, index) => {

                      return (<Col span={24} style={{ display: 'flex' }}>
                        <Input key={index} placeholder="Ingrese un correo" value={val} onChange={(e) => {
                          let emailTmp = [...emailcc]
                          emailTmp[index] = e.target.value
                          setEmailcc(emailTmp)
                        }} />
                        <Button
                          className="btn-orange-outlined"
                          onClick={() => {

                            let emailTmp = [...emailcc]
                            emailTmp.splice(index, 1)
                            setEmailcc(emailTmp)
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Col>)
                    }) : ''
                  }




                </Row>

                <Col span={24}>
                  <Button
                    className="btn-orange-outlined"
                    onClick={() => { if (emailcc.length < 4) setEmailcc([...emailcc, ...['']]) }}
                  >
                    <PlusOutlined /> Añadir
                  </Button>
                </Col>


              </Col>
              <Col
                xs={24}
                style={{ fontSize: "16px !important", marginTop: "24px", display: 'flex', justifyContent: 'center' }}
              >
                <Button
                  size="large"
                  className="btn-orange-outlined"
                  onClick={() => setTabActiveMobile(1)}
                >
                  Anterior
                </Button>

                <Button
                  size="large"
                  loading={loading}
                  className="btn-orange"
                  onClick={registerMobile}
                >
                  Confirmar Cita{" "}
                </Button>
              </Col>
            </Row>
          </Card>

          <Card
            className="advisorCard"
            style={
              tabActiveMobile == 3
                ? { marginBottom: "1rem" }
                : { display: "none" }
            }
          >
            {response != [] ? (
              <div>
                <div style={{
                  fontSize: "1.4rem",
                  color: "#f29314",
                  marginTop: ".5rem",
                  marginBottom: ".5rem",
                }}><CheckCircleOutlined

                  />
                  Se ha programado su asesoría correctamente
                </div>
                <p
                  style={{
                    fontSize: "1.6rem",
                    fontWeight: "bolder",
                    color: "#4a4a4a",
                    marginBottom: "1rem",
                  }}
                >
                  DATOS DE LA ASESORÍA
                </p>
                <p
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "bolder",
                    color: "#f29314",
                  }}
                >
                  Fecha
                </p>
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bolder",
                    color: "#4a4a4a",
                  }}
                >
                  {response.date}
                </p>
                <p
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "bolder",
                    color: "#f29314",
                  }}
                >
                  Asesor
                </p>
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bolder",
                    color: "#4a4a4a",
                  }}
                >
                  {response.adviser}
                </p>
                <p
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "bolder",
                    color: "#f29314",
                  }}
                >
                  Modalidad
                </p>
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bolder",
                    color: "#4a4a4a",
                  }}
                >
                  Virtual
                </p>
                <p
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "bolder",
                    color: "#f29314",
                  }}
                >
                  Link
                </p>
                <a
                  href={response.meet}
                  target="_blank"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bolder",
                    color: "#4a4a4a",
                  }}
                >
                  {response.meet}
                </a>
                <p
                  style={{
                    fontSize: "1rem",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <MailOutlined /> Se te ha enviado un correo con la información
                  de la asesoría a {response.email}
                </p>
                <Button
                  className="btn-orange"
                  size="large"
                  onClick={() => window.location.reload()}
                >
                  Programar otra asesoría
                </Button>
              </div>
            ) : (
              <span>Cargando ...</span>
            )}
          </Card>
        </Content>
      )}

      <Footer style={{ textAlign: "center" }}>© ITEPREVENGO {yearNow}</Footer>
    </Layout >
  )
}

export default Inscription
