import React, { useState, useEffect } from "react";
import { Card, Form, Input, Button, Row, Col, Select, Tag, message, Comment, Calendar } from "antd";
import { useSelector, useDispatch } from 'react-redux'
import { LeftOutlined, CheckOutlined, MailOutlined, DownOutlined, UpOutlined, CreditCardFilled, CloudUploadOutlined, ProfileOutlined, BankOutlined, TeamOutlined, StarFilled, StarOutlined, CheckCircleOutlined, MailFilled } from '@ant-design/icons';
import { Layout, Radio } from "antd";
import { Divider } from 'antd';

import { useLocation, Link } from "react-router-dom";

import axios from '../../util/Api';

import '../../styles/advisory.scss'
import { setResponsive } from "../../appRedux/actions/Responsive";
import { getUser } from "../../appRedux/actions/Auth";
import NavBar from "../../components/NavBarPublic";

import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es-mx";
import moment from "moment";

const { Content, Footer } = Layout;

const Inscription = () => {

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const responsive = useSelector(store => store.responsive.value)
    const authUser = useSelector(store => store.auth.authUser)

    const [tabActive, setTabActive] = useState(0)
    const [tabActiveMobile, setTabActiveMobile] = useState(0)



    const [loading, setLoading] = useState(false)
    const [id, setId] = useState('')
    const [availability, setAvailability] = useState([])
    const [selectedValue, setSelectedValue] = useState(moment());
    const [selectedHours, setSelectedHours] = useState("");
    const [hoursAvailability, setHoursAvailability] = useState([])
    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [response, setResponse] = useState([])


  const fechaActual = new Date();
  const yearNow = fechaActual.getFullYear();

    useEffect(() => {
        dispatch(setResponsive())
        range()
    }, [])




    const addDetail = (object) => {
        axios.post('/external/availability/addDetailGeneral', object, {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }
        ).then(({ data }) => {
            setHoursAvailability(data.hours)


        }).catch(function (error) {
            console.log("Error****:", error)
            setHoursAvailability([])

        })

    }

    const range = () => {
        axios.get('/external/availability/range', {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }
        ).then(({ data }) => {
            onSelect(moment(data[0], "YYYY-MM-DD"))
            // setAvailability([
            //     moment(data.today, "YYYY-MM-DD"),
            //     moment(data.endDate != null ? data.endDate : data.finish, "YYYY-MM-DD"),
            // ])

            setAvailability(data)

        }).catch(function (error) {
            console.log("Error****:", error)
            if (error.response) {
                message.error(error.response.data.message)

            } else {
                message.error('Error de red')

            }

        })

    }


    const addAdvisory = (object) => {
        axios.post('/external/advisory', object, {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }
        ).then(({ data }) => {
            setResponse(data)
            setTabActive(2)
            setTabActiveMobile(2)
            setLoading(false)


        }).catch(function (error) {
            console.log("Error****:", error)
            if (error.response) {
                message.error(error.response.data.message)

            } else {
                message.error('Error de red')

            }
            setLoading(false)

        })

    }
    //Calendar
    const onSelect = (value) => {

        setSelectedValue(value);
        addDetail({ date: value.format().substr(0, 10) })
    };

    //Hours
    const handleSelectedHour = (hour, occupied, idAdviser) => {

        if (occupied == 'OCUPADO') {
            message.warning("No disponible");
            return false;
        } else {
            setSelectedHours(hour);
            setId(idAdviser)
        }
    };

    const next = () => {
        if (selectedHours === "") {
            message.warning("Seleccione una hora");
            return false;
        } else {
            setTabActive(1)

        }
    };

    const nextMobile = () => {
        if (selectedHours === "") {
            message.warning("Seleccione una hora");
            return false;
        } else {
            setTabActiveMobile(1)

        }
    };

    const validate = () => {

        let RegexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})+$/;
        let $msg = ''
        if (email == '') {
            $msg = 'Ingrese un correo'
        }
        if (!RegexEmail.test(email)) {
            $msg = "La dirección de email es incorrecta."
        }

        if (lName == '') {
            $msg = 'Ingrese sus apellidos'
        }

        if (fName == '') {
            $msg = 'Ingrese su nombre'
        }

        if (id == '') {
            $msg = 'Seleccione un horario'
        }

        if ($msg != '') { message.warning($msg) }
        return $msg
    }


    const register = () => {


        if (validate() == '') {
            setLoading(true)

            addAdvisory({
                modality: 'Virtual',
                idAdviser: id,
                date: selectedValue.format().substr(0, 10),
                hours: selectedHours,
                fName: fName,
                lName: lName,
                email: email
            });
        }



    };


    const registerMobile = () => {

        if (validate() == '') {
            addAdvisory({
                modality: 'Virtual',
                idAdviser: id,
                date: selectedValue.format().substr(0, 10),
                hours: selectedHours,
                fName: fName,
                lName: lName,
                email: email

            });
        }



    };


    return (
        <Layout>
            <NavBar />
            {!responsive ?

                <Content className="site-layout" style={{ marginTop: '60px' }}>


                    <Row  >
                        <Col xs={20} lg={7}>
                            {/* <img src={employee.photo} height='150px' width='150px' style={{ borderRadius: '100px' }} />
                            <h4 className='primary'>{employee.fullName}</h4> */}
                            <h1>Solicitud de asesoría</h1>

                            {tabActive == 0 ? <p>Seleccione una fecha para acceder a una cita con nuestro asesor</p> : ''}
                            {tabActive == 1 ? <p>Complete el formulario para agendar su cita</p> : ''}
                            {tabActive == 2 ? <p>Se ha generado su cita correctamente</p> : ''}


                        </Col>
                        <Col style={tabActive != 0 ? {} : { display: 'none' }} xs={4} lg={1}>
                            <Divider type="vertical" style={{ backgroundColor: '#f29314', height: '100%' }} />

                        </Col>
                        <Col style={tabActive == 0 ? {} : { display: 'none' }} xs={20} lg={16}>

                            <Row>
                                <Col xs={24} lg={10} className="advisorCard" style={{ marginRight: '30px' }}>
                                    <p className='primary'>Seleccione fecha y hora</p>

                                    <Calendar
                                        // validRange={availability}
                                        showTime
                                        locale={locale}
                                        onSelect={onSelect}
                                        fullscreen={false}
                                        style={{ displey: "block" }}
                                        disabledDate={(current) => {
                                            if (!availability.includes(current.format().substr(0, 10))) {
                                                return current
                                            }
                                        }}
                                    />
                                </Col>
                                <Col xs={4} lg={1}>
                                    <Divider type="vertical" style={{ backgroundColor: '#f29314', height: '100%' }} />

                                </Col>
                                <Col xs={24} lg={10} className="advisorCard">

                                    <p
                                        style={{
                                            textAlign: "center",
                                            color: "#072d70",
                                        }}
                                    >
                                        {selectedValue && selectedValue.format("dddd D [de] MMMM")}
                                    </p>

                                    <div style={{ textAlign: "center" }}>
                                        {hoursAvailability.length > 0 ? hoursAvailability.map((hour, i) => (
                                            <span
                                                onClick={() => {
                                                    handleSelectedHour(hour.hour, hour.occupied, hour.idAdviser);
                                                }}
                                                className={`hourTag ${hour.occupied == 'OCUPADO' ? "hourOccupied" :
                                                    selectedHours.includes(hour.hour)
                                                        ? "hourSelected"
                                                        : "hourNotSelected"
                                                    }`}
                                            >
                                                {hour.hour.substring(0, 5)}
                                            </span>
                                        )) : <p>No disponible</p>}

                                    </div>
                                    {hoursAvailability.length > 0 ?
                                        <div style={{ textAlign: "center" }}>
                                            <Button className="btn-orange" onClick={next}>
                                                Siguiente</Button>
                                        </div> : ''}

                                </Col>
                            </Row>
                        </Col>





                        <Col lg={16} xs={20} style={tabActive == 1 ? {} : { display: 'none' }}>
                            <Card className='advisorCard' style={{ marginBottom: '1rem' }}>
                                <Row >
                                    <Col xs={24} style={{ textAlign: 'center', fontSize: '20px', marginBottom: '10px' }}>  <label className='primary' ><b>Introduzca sus datos</b></label></Col>

                                    <Col xs={6} > <span className='primary' ><b>Nombres</b></span></Col>
                                    <Col xs={18} > <Input size='' onChange={(e) => setFName(e.target.value)} /></Col>
                                    <Col xs={6} > <span className='primary' ><b>Apellidos</b></span></Col>
                                    <Col xs={18} > <Input size='' onChange={(e) => setLName(e.target.value)} /></Col>
                                    <Col xs={6} > <span className='primary' ><b>Correo</b></span></Col>
                                    <Col xs={18} > <Input size='' onChange={(e) => setEmail(e.target.value)} /></Col>
                                    <br></br><br></br>
                                    <Col xs={24} ><Button className="btn-orange-outlined" onClick={() => setTabActive(0)}>
                                        <LeftOutlined />   Anterior</Button><Button loading={loading} className="btn-orange" onClick={register}>
                                            Confirmar Cita <CheckOutlined /></Button></Col>

                                </Row>

                            </Card>
                        </Col>



                        <Col lg={16} xs={20} style={tabActive == 2 ? {} : { display: 'none' }}>
                            <Card className='advisorCard' style={{ marginBottom: '1rem' }}>
                                {response != [] ?
                                    <div>
                                        <div style={{
                                            fontSize: "1.4rem",
                                            color: "#f29314",
                                            marginTop: ".5rem",
                                            marginBottom: ".5rem",
                                        }}><CheckCircleOutlined

                                            />
                                     Se ha programado su asesoría correctamente
                                    </div>
                                        <p
                                            style={{
                                                fontSize: "1.6rem",
                                                fontWeight: "bolder",
                                                color: "#4a4a4a",
                                                marginBottom: "1rem",
                                            }}
                                        >
                                            DATOS DE LA ASESORÍA
            </p>
                                        <p
                                            style={{
                                                fontSize: "1.4rem",
                                                fontWeight: "bolder",
                                                color: "#f29314",
                                            }}
                                        >
                                            Fecha
            </p>
                                        <p
                                            style={{
                                                fontSize: "1rem",
                                                fontWeight: "bolder",
                                                color: "#4a4a4a",
                                            }}
                                        >
                                            {response.date}
                                        </p>
                                        <p
                                            style={{
                                                fontSize: "1.4rem",
                                                fontWeight: "bolder",
                                                color: "#f29314",
                                            }}
                                        >
                                            Asesor
            </p>
                                        <p
                                            style={{
                                                fontSize: "1rem",
                                                fontWeight: "bolder",
                                                color: "#4a4a4a",
                                            }}
                                        >
                                            {response.adviser}
                                        </p>
                                        <p
                                            style={{
                                                fontSize: "1.4rem",
                                                fontWeight: "bolder",
                                                color: "#f29314",
                                            }}
                                        >
                                            Modalidad
            </p>
                                        <p
                                            style={{
                                                fontSize: "1rem",
                                                fontWeight: "bolder",
                                                color: "#4a4a4a",
                                            }}
                                        >
                                            Virtual
            </p>
                                        <p
                                            style={{
                                                fontSize: "1.4rem",
                                                fontWeight: "bolder",
                                                color: "#f29314",
                                            }}
                                        >
                                            Link
            </p>
                                        <a
                                            href={response.meet}
                                            target='_blank'
                                            style={{
                                                fontSize: "1rem",
                                                fontWeight: "bolder",
                                                color: "#4a4a4a",
                                            }}
                                        >
                                            {response.meet}
                                        </a>
                                        <p
                                            style={{
                                                fontSize: "1rem",
                                                marginTop: "1rem",
                                                marginBottom: "1rem",
                                            }}
                                        >
                                            <MailOutlined /> Se te ha enviado un correo con la información
                de la asesoría a {response.email}
                                        </p>
                                        <Button
                                            className="btn-orange"
                                            size="large"
                                            onClick={() => window.location.reload()}
                                        >
                                            Programar otra asesoría
                  </Button>
                                    </div> :
                                    <span>Cargando ...</span>}

                            </Card>
                        </Col>
                    </Row>


                </Content>

                :

                <Content className="site-layout" style={{ marginTop: '80px' }}>
                    <h1 style={{ textAlign: 'center' }}>Solicitud de asesoría</h1>

                    <Card className='advisorCard' style={tabActiveMobile == 0 ? { marginBottom: '1rem' } : { display: 'none' }}>
                        <Row >
                            <Col xs={24} >
                                <p className='primary'>Seleccione fecha y hora</p>

                                <Calendar
                                    // validRange={availability}
                                    showTime
                                    locale={locale}
                                    onSelect={onSelect}
                                    fullscreen={false}
                                    style={{ displey: "block" }}
                                    disabledDate={(current) => {
                                        if (!availability.includes(current.format().substr(0, 10))) {
                                            return current
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs={1} >
                                <Divider type='horizontal' style={{ backgroundColor: '#f29314', width: '270px' }} />

                            </Col>
                            <Col xs={24} >

                                <p
                                    style={{
                                        textAlign: "center",
                                        color: "#072d70",
                                    }}
                                >
                                    {selectedValue && selectedValue.format("dddd D [de] MMMM")}
                                </p>

                                <div style={{ textAlign: "center" }}>
                                    {hoursAvailability.length > 0 ? hoursAvailability.map((hour, i) => (
                                        <span
                                            onClick={() => {
                                                handleSelectedHour(hour.hour, hour.occupied, hour.idAdviser);
                                            }}
                                            className={`hourTag ${hour.occupied == 'OCUPADO' ? "hourOccupied" :
                                                selectedHours.includes(hour.hour)
                                                    ? "hourSelected"
                                                    : "hourNotSelected"
                                                }`}
                                        >
                                            {hour.hour.substring(0, 5)}
                                        </span>
                                    )) : <p>No disponible</p>}

                                </div>
                                {hoursAvailability.length > 0 ?
                                    <div style={{ textAlign: "center" }}>
                                        <Button className="btn-orange" onClick={nextMobile}>
                                            Siguiente</Button>
                                    </div> : ''}

                            </Col>

                        </Row>
                    </Card>

                    <Card className='advisorCard' style={tabActiveMobile == 1 ? { marginBottom: '1rem', textAlign: 'left' } : { display: 'none' }}>
                        <Row >
                            <Col xs={24} style={{ textAlign: 'center', fontSize: '20px', marginBottom: '30px' }}>  <label className='primary' ><b>Introduzca sus datos</b></label></Col>

                            <Col xs={24} > <span className='primary' ><b>Nombres</b></span></Col>
                            <Col xs={24} > <Input size='' onChange={(e) => setFName(e.target.value)} /></Col>
                            <Col xs={24} > <span className='primary' ><b>Apellidos</b></span></Col>
                            <Col xs={24} > <Input size='' onChange={(e) => setLName(e.target.value)} /></Col>
                            <Col xs={24} > <span className='primary' ><b>Correo</b></span></Col>
                            <Col xs={24} > <Input size='' onChange={(e) => setEmail(e.target.value)} /></Col>
                            <Col xs={10} style={{ fontSize: '16px !important', marginTop: '10px' }}>
                                <Button size='large' icon={<LeftOutlined />} className="btn-orange-outlined" onClick={() => setTabActiveMobile(1)}>
                                    Anterior</Button></Col>
                            <Col xs={14} style={{ fontSize: '16px !important', marginTop: '10px' }} >
                                <Button loading={loading} size='large' className="btn-orange" onClick={registerMobile}>
                                    Confirmar Cita </Button></Col>

                        </Row>

                    </Card>

                    <Card className='advisorCard' style={tabActiveMobile == 2 ? { marginBottom: '1rem' } : { display: 'none' }}>
                        {response != [] ?
                            <div>
                                <div style={{
                                    fontSize: "1.4rem",
                                    color: "#f29314",
                                    marginTop: ".5rem",
                                    marginBottom: ".5rem",
                                }}><CheckCircleOutlined

                                    />
                                     Se ha programado su asesoría correctamente
                                    </div>
                                <p
                                    style={{
                                        fontSize: "1.6rem",
                                        fontWeight: "bolder",
                                        color: "#4a4a4a",
                                        marginBottom: "1rem",
                                    }}
                                >
                                    DATOS DE LA ASESORÍA
</p>
                                <p
                                    style={{
                                        fontSize: "1.4rem",
                                        fontWeight: "bolder",
                                        color: "#f29314",
                                    }}
                                >
                                    Fecha
</p>
                                <p
                                    style={{
                                        fontSize: "1rem",
                                        fontWeight: "bolder",
                                        color: "#4a4a4a",
                                    }}
                                >
                                    {response.date}
                                </p>
                                <p
                                    style={{
                                        fontSize: "1.4rem",
                                        fontWeight: "bolder",
                                        color: "#f29314",
                                    }}
                                >
                                    Asesor
</p>
                                <p
                                    style={{
                                        fontSize: "1rem",
                                        fontWeight: "bolder",
                                        color: "#4a4a4a",
                                    }}
                                >
                                    {response.adviser}
                                </p>
                                <p
                                    style={{
                                        fontSize: "1.4rem",
                                        fontWeight: "bolder",
                                        color: "#f29314",
                                    }}
                                >
                                    Modalidad
</p>
                                <p
                                    style={{
                                        fontSize: "1rem",
                                        fontWeight: "bolder",
                                        color: "#4a4a4a",
                                    }}
                                >
                                    Virtual
</p>
                                <p
                                    style={{
                                        fontSize: "1.4rem",
                                        fontWeight: "bolder",
                                        color: "#f29314",
                                    }}
                                >
                                    Link
</p>
                                <a
                                    href={response.meet}
                                    target='_blank'
                                    style={{
                                        fontSize: "1rem",
                                        fontWeight: "bolder",
                                        color: "#4a4a4a",
                                    }}
                                >
                                    {response.meet}
                                </a>
                                <p
                                    style={{
                                        fontSize: "1rem",
                                        marginTop: "1rem",
                                        marginBottom: "1rem",
                                    }}
                                >
                                    <MailOutlined /> Se te ha enviado un correo con la información
  de la asesoría a {response.email}
                                </p>
                                <Button
                                    className="btn-orange"
                                    size="large"
                                    onClick={() => window.location.reload()}
                                >
                                    Programar otra asesoría
                  </Button>
                            </div> :

                            <span>Cargando ...</span>}


                    </Card>
                </Content>


            }
            <Footer style={{ textAlign: 'center' }}>© ITEPREVENGO {yearNow}</Footer>
        </Layout >
    );
};


export default Inscription;