import {RESPONSIVE} from '../ActionTypes'

const INIT_STATE = {
  value: false,
 
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESPONSIVE: {
      return {...state, value: action.payload};
    }
 
    default:
      return state;
  }
}
