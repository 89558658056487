import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "../util/asyncComponent";
import InfoView from "../components/InfoView";

//import Storage from "./storage/index";
//import Order from "./order/index";
//import CashManagement from "./cashManagement/index";

const App = ({match}) => (
    <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>
      <Route path={`${match.url}user`} component={asyncComponent(() => import('./User'))}/>
      <Route path={`${match.url}changepassword`} component={asyncComponent(() => import('./User/UserChangePassword'))} />
      <Route path={`${match.url}base`} component={asyncComponent(() => import('./Base'))}/>
      <Route path={`${match.url}reports`} component={asyncComponent(() => import('./Reports'))}/>


    </Switch>
    <InfoView/>

  </div>
);

export default App;
