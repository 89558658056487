import React,{useState,useEffect} from "react";
import { Link } from 'react-router-dom';
import { Layout, Menu } from "antd";
import { PoweroffOutlined, UserOutlined } from '@ant-design/icons';

import "../../styles/kernel.scss";

import {userSignOut} from "../../appRedux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";

const { Header} = Layout;

const NavBar = (props) => {
    const dispatch = useDispatch();
    
    return(
        <Header className="navBar" style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
            <Link to="/"><div className="logo" /></Link>
            <Menu className="ul-navbar"  mode="horizontal" >
            
                {  
                    props.items?
                    props.items.map((item,key) => {
                        return(<Menu.Item key={key}> <a href={item.link}>{item.menu}</a> </Menu.Item>)
                    } )
                    :
                    ''   
                }
            </Menu>
            <div style={{ position: 'fixed',fontSize: '1.5rem',top: 0, right:0, marginRight: '20px' }}>
            <Link to="/user">
            <UserOutlined style={{marginRight: '1rem', color: '#072d70'}} />
            </Link>
                <PoweroffOutlined style={{color: '#072d70'}} onClick={() => dispatch(userSignOut())} />
            </div>
        </Header>
    );

}

export default NavBar;