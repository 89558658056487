import { RESPONSIVE } from "../ActionTypes";


export const setResponsive = () => {
  return (dispatch) => {

    var mql = window.matchMedia("(max-width: 640px)");

    if (window.matchMedia("(max-width: 640px)").matches) {
      // La media querie coincide
      dispatch({ type: RESPONSIVE, payload: true });

    } else {
      dispatch({ type: RESPONSIVE, payload: false });

    }

    mql.addListener(function (event) {
      if (event.matches) {
        // La media querie coincide
        dispatch({ type: RESPONSIVE, payload: true });
      } else {
        dispatch({ type: RESPONSIVE, payload: false });
      }
    });
  }
};











