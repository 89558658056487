import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import { ConfigProvider } from 'antd';
import axios from '../../util/Api';

import MainApp from "./MainApp";
import Login from "../Login";
import ForgotPassword from "../ForgotPassword";
import ByAdviser from "../Advisory/ByAdviser";
import General from "../Advisory/General";



import {getUser,setInitUrl} from "../../appRedux/actions/Auth";


const RestrictedRoute = ({component: Component, location, token, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/Login',
            state: {from: location}
          }}
        />}
  />;


const App = () => {

  const dispatch = useDispatch();
  const {token, initURL, authUser, toChange} = useSelector(({auth}) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();


  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = "/css/style.css";
    link.className = 'gx-style';
    document.body.appendChild(link);
  });

 

  useEffect(() => {
    if(authUser&&token){
      console.log("tochange:",toChange);

      if(toChange === 1){
        console.log("redirigiendo desde cualquier ruta!");
        if(location.pathname != '/changepassword' && location.pathname != '/home'){
          history.push('/changepassword');
        }
      }
    }
    if (location.pathname === '/') {
      if (token === null) {
        history.push('/Login');
      } else if (initURL === '' || initURL === '/' || initURL === '/Login') {
        dispatch(getUser());        
        history.push('/home');
      }else{
            dispatch(getUser());
            history.push(initURL);

      }
    }else if(location.pathname === '/home'){
      if(token){
        dispatch(getUser());
      }
        
    }
    if(token){
      axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    }
    
    
    
  }, [token, initURL, location, history]);


  return (
    <ConfigProvider>
        <Switch>
          <Route exact path='/login' component={Login}/>
          <Route exact path='/forgot-password' component={ForgotPassword}/>
          <Route exact path='/advisory/byadviser' component={ByAdviser}/>
          <Route exact path='/advisory/general' component={General}/>
          <Route exact path='/advisory/' component={General}/>




          <RestrictedRoute path={`${match.url}`} token={token} location={location}
                           component={MainApp}/>
        </Switch>
    </ConfigProvider>
  )
};

export default memo(App);
